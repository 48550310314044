import './my-component.css';
const template = document.createElement("template");

template.innerHTML = `
        
<div id="datepicker-inline" inline-datepicker data-date="02/25/2024"></div>


`;

export default template;