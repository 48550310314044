import template from './my-component.mjs'

class MyComponent extends HTMLElement {
    constructor() {
      super()
      this.appendChild(template.content.cloneNode(true))
    }
  }
  
  // Define the custom element
  customElements.define('my-component', MyComponent)